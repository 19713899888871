import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";
import { COMPLETE, TASK, WALK } from "@miview/constants";
import moment from "moment";

const API_WALKS = `${API_V3}/walks`;

export const walkService = {
  getAll: async ({ params, filters, etag, signal, sort }) => {
    const options = await apiOptions({
      url: `${API_WALKS}`,
      method: "get",
      params,
      filters,
      etag,
      signal,
      sort,
    });

    return apiRequest(options);
  },

  create: async (data) => {
    const options = await apiOptions({
      url: `${API_WALKS}`,
      method: "post",
      data,
    });

    return apiRequest(options);
  },

  update: async (id, body, toastMessageOverride = {}) => {
    const options = await walkService.updateOptions(id, body);

    const toastMessageConfig = {
      success: {
        message: "Work saved successfully",
      },
      ...toastMessageOverride,
    };
    return apiRequest(options, toastMessageConfig);
  },

  updateOptions: async (id, body) => {
    return apiOptions({
      url: `${API_WALKS}/${id}`,
      method: "patch",
      data: body,
    });
  },

  getItems: async (id) => {
    const params = {
      url: `${API_WALKS}/${id}/items`,
    };

    const options = await apiOptions(params);
    return apiRequest(options);
  },
  getWalkItems: async ({ id, filters, signal }) => {
    const params = {
      url: `${API_WALKS}/${id}/walkitems`,
      filters,
      signal,
    };

    const options = await apiOptions(params);
    return apiRequest(options);
  },
  createIssueOptions: async (id, data) => {
    return apiOptions({
      url: `${API_WALKS}/${id}/issues`,
      method: "post",
      data,
    });
  },

  createIssue: async (id, data) => {
    const options = await apiOptions({
      url: `${API_WALKS}/${id}/issues`,
      method: "post",
      data,
    });
    return apiRequest(options);
  },

  getIssues: async (id) => {
    const params = {
      url: `${API_WALKS}/${id}/issues`,
    };

    const options = await apiOptions(params);
    return apiRequest(options);
  },

  getWalkExpand: async (id, expand, signal) => {
    const params = {
      url: `${API_WALKS}/${id}`,
      params: { expand },
      signal,
    };

    const options = await apiOptions(params);
    return apiRequest(options);
  },

  getAssignableUsers: async (id, filterByStage = false) => {
    const options = await apiOptions({
      url: `${API_WALKS}/${id}/assignableUsers?filterByStage=${filterByStage}`,
    });
    return apiRequest(options);
  },

  getExtensions: async (id) => {
    const options = await apiOptions({
      url: `${API_WALKS}/${id}/extensions`,
    });
    return apiRequest(options);
  },

  cancelStageWalk: async (walkId, cancelReason, successMessage) => {
    const options = await apiOptions({
      url: `${API_WALKS}/${walkId}/cancel`,
      method: "post",
      data: { cancelReason },
    });

    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  assignUserToWalk: async (id, data, toastMessageOverride = {}) => {
    const options = await apiOptions({
      method: "post",
      url: `${API_WALKS}/${id}/assignment`,
      data,
    });

    const toastMessageConfig = {
      success: {
        message: "User assigned successfully",
      },
      ...toastMessageOverride,
    };

    return apiRequest(options, toastMessageConfig);
  },

  getPropertySections: async (id) => {
    const options = await apiOptions({
      url: `${API_WALKS}/${id}/propertySections`,
    });
    return apiRequest(options);
  },

  reset: async (id) => {
    const options = await apiOptions({
      method: "post",
      url: `${API_WALKS}/${id}/reset`,
    });

    const toastMessageConfig = {
      success: {
        message: "Work reset successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  addWalkItemAttachmentOptions: async (data) => {
    return apiOptions({
      method: "post",
      url: `${API_WALKS}/${data.stageWalkId}/items/${data.stageWalkItemId}/attachments`,
      data,
    });
  },

  deleteWalkItemAttachmentOptions: async (walkId, itemId, fileName) => {
    return apiOptions({
      method: "delete",
      url: `${API_WALKS}/${walkId}/items/${itemId}/attachments/filename/${fileName}`,
    });
  },

  updateWalkItemOptions: async (walkId, itemId, data) => {
    let options = await apiOptions({
      method: "patch",
      url: `${API_WALKS}/${walkId}/items/${itemId}`,
      data,
    });
    options.context = TASK;
    options.title = "Update Checklist";
    return options;
  },

  updateWalkItem: async (walkId, itemId, data) => {
    const options = await apiOptions({
      method: "patch",
      url: `${API_WALKS}/${walkId}/items/${itemId}`,
      data,
    });
    return apiRequest(options);
  },

  addWalkItemImage: async (walkId, itemId, data) => {
    const options = await apiOptions({
      method: "post",
      url: `${API_WALKS}/${walkId}/items/${itemId}/attachments`,
      data,
    });
    return apiRequest(options);
  },

  completeWalk: async (walkId, data) => {
    const options = await apiOptions({
      method: "post",
      url: `${API_WALKS}/${walkId}/complete`,
      data,
    });
    return apiRequest(options);
  },

  completeOptions: async (walkId, data) => {
    let options = await apiOptions({
      method: "post",
      url: `${API_WALKS}/${walkId}/complete`,
      data: {
        stageSummary: data?.stageSummary,
        stageSummaryIds: data?.stageSummaryIds,
        walkStatus: data?.walkStatus ? data.walkStatus : COMPLETE,
        signatureURL: data?.signatureURL,
        isReadyForInspection: data?.isReadyForInspection,
        endLatitude: data?.endLatitude || 0,
        endLongitude: data?.endLongitude || 0,
        walkEndDate: moment().format(),
        walkId,
      },
    });
    options.context = WALK;
    options.title = "Complete Walk";
    return options;
  },

  reschedule: async (walkId, data) => {
    const options = await apiOptions({
      method: "post",
      url: `${API_WALKS}/${walkId}/reschedule`,
      data,
    });
    return apiRequest(options);
  },

  startJobOptions: async (walkId, data) => {
    let options = await apiOptions({
      method: "POST",
      url: `${API_WALKS}/${walkId}/start`,
      data,
    });
    options.context = WALK;
    options.title = "Start Walk";
    return options;
  },

  recalculateWorkPay: async (id) => {
    const options = await apiOptions({
      url: `${API_WALKS}/${id}/calculatedPay`,
      method: "GET",
    });
    return apiRequest(options);
  },
};
