/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";
import {
  Modal,
  DialogActions,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";
import { MiButton, MiLoader, MiIcon, MiBox } from "../index.ts";
import { mdiClose } from "@mdi/js";

const Title = styled(DialogTitle, {
  shouldForwardProp: (props) =>
    !["titleColor", "disableTypography"].includes(props),
})(({ theme, titleColor }) => ({
  fontSize: theme.fontSize.xxlarge,
  color: titleColor || theme.palette.primary.main || "red",
}));

const HeaderWrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

const StyledModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const Actions = ({ actions = [] }) => {
  if (!actions.length) {
    return null;
  }
  return actions.map((action, i) => {
    const { style = {} } = action;

    return (
      <MiButton
        key={i}
        title={action.name.toUpperCase()}
        onClick={action.onClick}
        color={action.color}
        style={{
          marginLeft: 30,
          minWidth: 120,
          ...style,
        }}
        inverse={action.inverse}
        disabled={action.disabled}
      />
    );
  });
};

export const MiModal = ({
  title,
  open,
  titleColor,
  onClose,
  children,
  maxWidth = "50%",
  maxHeight = "50vh",
  actions,
  contentStyles = {},
  isCloseButton = true,
  loading,
}) => {
  return (
    <StyledModal
      onClose={onClose}
      open={open}
      disableScrollLock
      data-testid="miModal"
    >
      <>
        <MiBox maxWidth={maxWidth}>
          <HeaderWrapper>
            <Title titleColor={titleColor} disableTypography>
              {title}
            </Title>
            {isCloseButton && (
              <IconButton
                size={"medium"}
                onClick={onClose}
                style={{ width: 50, height: 50 }}
              >
                <MiIcon path={mdiClose} size={1} data-testid="CloseIcon" />
              </IconButton>
            )}
          </HeaderWrapper>
          <div
            style={{
              padding: "0px 24px",
              maxHeight: maxHeight,
              overflowY: "auto",
              ...contentStyles,
            }}
          >
            {loading && <MiLoader type="linear" />}
            {children}
          </div>
          <DialogActions style={{ margin: 10 }}>
            <Actions actions={actions} />
          </DialogActions>
        </MiBox>
      </>
    </StyledModal>
  );
};
