import React, { useEffect } from "react";
import { routes } from "../../routes";
import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";
import disableScroll from "disable-scroll";
import { Helmet } from "react-helmet";
import { checkIfLoggedIn } from "../../api/login";
import mainNav from "../../_nav";
import { history } from "../../App";
import { Session } from "@miview/components";
import { useRouter } from "@miview/hooks";
import { MiBuildLogo } from "@miview/assets";
import { APPLIST } from "@miview/constants";
import { ScrollToTop } from "@miview/components";

const capitalize = (s) => {
  if (typeof s !== "string") {
    return "";
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const createTitle = (path) => {
  if (path) {
    const pathArr = path.split("/");

    if (pathArr.length > 2) {
      const subRoute = pathArr.splice(1).map((p) => capitalize(p));

      return subRoute.join(" - ");
    }

    if (pathArr) {
      return `MiView - ${capitalize(pathArr.pop())}`;
    }

    return "MiView Admin";
  }
};

const DefaultLayout = (props) => {
  const { showLoadingOverlay } = props;

  useEffect(() => {
    disableScroll.off();
    const unlisten = history.listen(() => {
      const currentPath = window.location.pathname;
      if (!["/500", "/404"].includes(currentPath)) {
        localStorage.setItem("lastVisitedRoute", window.location.pathname);
      }
    });

    const nav = JSON.parse(localStorage.getItem("navConfig"));
    if (!nav) {
      checkIfLoggedIn();
    }

    return () => {
      unlisten();
    };
  }, []);

  const { pathname } = useRouter();

  useEffect(() => {
    if (showLoadingOverlay) {
      disableScroll.on();
    } else {
      disableScroll.off();
    }
  }, [showLoadingOverlay]);

  const renderFooter = () => {
    return <DefaultFooter />;
  };

  const renderRightIcons = () => {
    return <DefaultHeader />;
  };

  const MAIN_APP = {
    logo: MiBuildLogo,
    name: "MiBuild",
  };

  const getUser = () => {
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");
    return {
      firstName,
      lastName,
      fullName: `${firstName} ${lastName}`,
    };
  };

  return (
    <div className="app">
      <ScrollToTop>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{createTitle(pathname)}</title>
        </Helmet>
        <Session
          mainApp={MAIN_APP}
          apps={APPLIST}
          nav={mainNav}
          routesInfo={routes}
          renderFooter={renderFooter}
          renderRightIcons={renderRightIcons}
          user={getUser()}
          admin={true}
        />
      </ScrollToTop>
    </div>
  );
};

export default DefaultLayout;
