import React, { useState, useEffect, useContext } from "react";
import { login, logout } from "../../../api/login";
import { THEME } from "@miview/theme";
import { fileService, userService } from "@miview/api";
import { MiBuildLogo, MiViewLogo } from "@miview/assets";
import { useRouter } from "@miview/hooks";
import { isValidEmail, appStorage } from "@miview/utils";
import { MiInputTextbox, MiLink, MiButton, MiModal } from "@miview/components";
import { createToast } from "@miview/toast";
import {
  TOAST_TYPES,
  UNAUTHORIZED_ERROR,
  CDN_STORAGE_PARAM,
  CDN_DEFAULT,
} from "@miview/constants";
import { useTheme } from "@mui/material";
import { AppInsightsContext } from "@miview/providers";

const LoginScreen = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPasswordInProgress, setForgotPasswordInProgress] =
    useState(false);
  const [forgotPwSubmitted, setForgotPwSubmitted] = useState();
  const [forgotPwEmail, setForgotPwEmail] = useState();
  const [forgotPasswordSubmitError, setForgotPasswordSubmitError] = useState();
  const getAppInsightsConnectionString = useContext(AppInsightsContext);

  const { navigate } = useRouter();
  const theme = useTheme();

  useEffect(() => {
    if (localStorage.getItem("redirectingToLogin") === "true") {
      logout();
      navigate("/login");
      return;
    }
    if (localStorage.getItem("userLoggedIn") === "true") {
      let search = window.location.search;
      const hash = window.location.hash;
      if (
        window.location.hostname === "localhost" &&
        hash.indexOf("redirect=") > -1
      ) {
        search = hash.slice(hash.indexOf("redirect=") + 9, hash.length);
      }

      if (search.length > 1) {
        navigate(decodeURIComponent(search));
      } else {
        navigate("/homes");
      }
    } else {
      logout();
      navigate("/login");
    }
  }, []);

  const loginLocal = async () => {
    localStorage.setItem("redirectingToLogin", false);
    const loggedIn = await login(userName, password);
    const authorizedUser = loggedIn.permissions?.includes("CanLoginMiTradeWeb");
    if (!loggedIn) {
      return;
    }
    await getAppInsightsConnectionString();
    if (authorizedUser) {
      const cdn = await fileService.getCDNURI();
      await appStorage.setItem(CDN_STORAGE_PARAM, cdn.data || CDN_DEFAULT);
      navigate("/homes");
      return;
    }
    createToast(UNAUTHORIZED_ERROR, TOAST_TYPES.ERROR);
  };

  const toggleResetPassword = () => {
    setForgotPwSubmitted(false);
    setForgotPasswordSubmitError(false);
    setForgotPasswordInProgress(!forgotPasswordInProgress);
    setForgotPwEmail("");
  };

  const submitForgotPassword = () => {
    if (isValidEmail(forgotPwEmail)) {
      setForgotPasswordSubmitError(false);
      setForgotPwSubmitted(true);
      userService.initiatePasswordReset(forgotPwEmail);
    } else {
      setForgotPasswordSubmitError(true);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key !== "Enter") {
      return;
    }
    loginLocal();
  };

  const actions = [
    {
      name: "Cancel",
      onClick: toggleResetPassword,
      color: theme.palette.medium.grey,
      inverse: false,
    },
    {
      name: forgotPwSubmitted ? "OK" : "Submit",
      onClick: () =>
        forgotPwSubmitted ? toggleResetPassword() : submitForgotPassword(),
      color: theme.palette.primary.main,
      inverse: true,
      disabled: !forgotPwSubmitted ? !forgotPwEmail : false,
    },
  ];

  return (
    !localStorage.getItem("token") && (
      <div style={styles.container}>
        <MiModal
          title="Reset Password"
          open={forgotPasswordInProgress}
          onClose={toggleResetPassword}
          actions={!forgotPwSubmitted ? actions : [actions.pop()]}
        >
          {forgotPwSubmitted ? (
            <div>
              If there is an account associated with this email, you will
              receive a link to reset your password in your inbox shortly.
            </div>
          ) : (
            <MiInputTextbox
              labelText="Email:"
              type="text"
              style={{ marginTop: 5 }}
              id="forgot-pw-email"
              name="forgot-pw-email"
              value={forgotPwEmail}
              handleChange={(e) => setForgotPwEmail(e.target.value)}
              size="small"
              required
              error={forgotPasswordSubmitError}
              helperText={forgotPasswordSubmitError ? "Invalid Email !" : ""}
            />
          )}
        </MiModal>

        <img
          src={MiBuildLogo}
          style={{
            margin: 25,
            marginTop: -10,
            zIndex: 1,
            maxWidth: 400,
            maxHeight: 110,
          }}
        />

        <div style={styles.card}>
          <h1>Login</h1>
          <p className="text-muted">Sign in to your account</p>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <i
              style={{ flex: 0, padding: ".5rem .5rem .5rem 0" }}
              className="icon-user"
            ></i>
            <MiInputTextbox
              styles={{ flex: 1 }}
              placeholder="Username"
              value={userName}
              onKeyDown={handleKeyPress}
              variant="outlined"
              handleChange={(e) => setUserName(e.target.value)}
            />
          </div>

          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}
          >
            <i
              style={{ flex: 0, padding: ".5rem .5rem .5rem 0" }}
              className="icon-lock"
            ></i>
            <MiInputTextbox
              styles={{ flex: 1 }}
              placeholder="Password"
              type="password"
              variant="outlined"
              value={password}
              onKeyDown={handleKeyPress}
              handleChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <MiButton
              onClick={loginLocal}
              color={theme.palette.primary.main}
              style={{ width: 100 }}
              title="Login"
              inverse
            />
            <MiLink onClick={toggleResetPassword} title="Forgot password?" />
          </div>
        </div>
        <img
          src={MiViewLogo}
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: "200px",
            marginRight: "10px",
            float: "right",
          }}
        />
      </div>
    )
  );
};

export default LoginScreen;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    width: "100vw",
    minHeight: "100vh",
  },
  card: {
    borderRadius: ".3rem",
    width: 500,
    padding: "3rem",
    borderColor: THEME.GREY_LIGHT,
    borderWidth: 1,
    borderStyle: "solid",
    background: "white",
  },
};
