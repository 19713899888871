import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { MiTHEME } from "@miview/theme";
import {
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/system";

const Wrapper = styled(FormControl, {
  shouldForwardProp: (props) => !["minWidth"].includes(props),
})(({ minWidth }) => ({
  minWidth: minWidth !== undefined ? minWidth : 220,
  display: "flex",
  margin: "5px 0px 5px 0px",
}));

const StyledSelect = styled(Select, {
  shouldForwardProp: (props) =>
    ![
      "paddingRight",
      "minWidth",
      "marginBottom",
      "marginRight",
      "InputProps",
      "InputLabelProps",
      "clickButton",
    ].includes(props),
})(({ theme }) => ({
  fontSize: theme.fontSize.small,
  backgroundColor: theme.palette.primary.white,
  borderWidth: "2px",
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.blue,
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.blue,
    borderWidth: "2px",
  },
}));

export const MiInputSelectBox = ({
  labelText,
  labelField,
  valueField,
  menuItems,
  variant = "standard",
  value,
  handleChange,
  required,
  disabled,
  disableNone,
  minWidth,
  action,
  styles,
  error,
  placeholderText,
  isPlaceholder,
  helperText,
  renderValue,
  ...rest
}) => {
  const [menuProp, setMenuProp] = useState({
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getcontentanchorel: null,
    PaperProps: {
      style: {
        maxHeight: 250,
        width: 220,
      },
    },
  });

  const getValue = (row) => {
    return row[valueField] || row.value;
  };

  const inputRef = useRef();
  const getMenuItems = () => {
    if (!menuItems?.length) {
      return null;
    }
    return menuItems.map((item, idx) => (
      <MenuItem
        key={idx}
        value={getValue(item)}
        style={styles}
        disabled={item.disabled}
      >
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {item.icon}
          {item[labelField] || item.label}
          <br></br>
          {!!item.secondaryInfo && <i>Pay Date: {item.secondaryInfo}</i>}
        </div>
      </MenuItem>
    ));
  };

  const renderAction = () => {
    if (!action) {
      return null;
    }
    return (
      <MenuItem disabled={action.disabled} onClick={action.onClick}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: action.color || MiTHEME.palette.primary.blue,
          }}
        >
          {action.renderIcon && <div>{action.renderIcon()}</div>}
          {action.text}
        </div>
      </MenuItem>
    );
  };

  const getDist = () => {
    const bottomheight = inputRef.current.getBoundingClientRect().bottom;
    if (window.innerHeight - bottomheight < 250) {
      return true;
    } else {
      return false;
    }
  };
  const getMenuprops = () => {
    if (getDist()) {
      setMenuProp({
        anchorOrigin: {
          vertical: -18,
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        getcontentanchorel: null,
        PaperProps: {
          style: {
            maxHeight: 250,
            width: 220,
          },
        },
      });
    } else {
      setMenuProp({
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        getcontentanchorel: null,
        PaperProps: {
          style: {
            maxHeight: 250,
            width: 220,
          },
        },
      });
    }
  };

  return (
    <div>
      <Wrapper
        variant={variant}
        size="small"
        error={error}
        style={styles}
        minWidth={minWidth}
        required={required}
      >
        <InputLabel id="select-label" shrink>
          {labelText}
        </InputLabel>
        <StyledSelect
          {...rest}
          disabled={disabled}
          ref={rest.ref || inputRef}
          label={labelText}
          labelId="select-label"
          required={required}
          variant={variant}
          value={!menuItems?.length ? "" : value || ""}
          onChange={handleChange}
          MenuProps={menuProp}
          onOpen={getMenuprops}
          renderValue={renderValue}
        >
          {!disableNone && (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          )}
          {isPlaceholder && (
            <MenuItem value="" disabled>
              {placeholderText || "Select"}
            </MenuItem>
          )}

          {getMenuItems()}
          {renderAction()}
        </StyledSelect>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </Wrapper>
    </div>
  );
};

MiInputSelectBox.propTypes = {
  labelText: PropTypes.string,
  menuItems: PropTypes.array,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  handleChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  disableNone: PropTypes.bool,
  placeholderText: PropTypes.string,
  isPlaceholder: PropTypes.bool,
  /**
   * Styles applied to the FormControl Tag.
   */
  styles: PropTypes.object,
};

MiInputSelectBox.defaultProps = {
  menuItems: [],
  isPlaceholder: false,
};
