import React from "react";
import { makeStyles } from "tss-react/mui";
import { MiTHEME } from "@miview/theme";
import { URLS, URLS_KEY } from "@miview/constants";

const useStyles = makeStyles()(() => {
  return {
    bannerContainer: {
      display: "flex",
      flexDirection: "row",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: Object.values(URLS.DEV).includes(global.baseUrl)
        ? MiTHEME.palette.primary.purple
        : MiTHEME.palette.primary.orange,
      padding: "3px",
    },
    bannerCss: {
      color: MiTHEME.palette.primary.white,
    },
  };
});

export const MiBanner = () => {
  const { classes } = useStyles();

  if (Object.values(URLS).includes(global.baseUrl)) {
    return (
      <div className={classes.bannerContainer}>
        <div className={classes.bannerCss}>{URLS_KEY[global.baseUrl]}</div>
      </div>
    );
  }
  return null;
};
