import React, { useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import { unregister } from "./registerServiceWorker";
// Styles
// Import Main styles for this application
import "./scss/style.css";
import "material-design-icons/iconfont/material-icons.css";
import { createBrowserHistory } from "history";
import { LoginScreen, ErrorView, ResetScreen } from "./views/Pages";
import "./globals/globals.js";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { TssCacheProvider } from "tss-react";
import { MiTHEME } from "@miview/theme";
import { AppInsightsProvider } from "@miview/providers";
import { PurchasingRoutes } from "./PurchasingRoutes";
import { AccountingRoutes } from "./AccountingRoutes";
import { SchedulingRoutes } from "./SchedulingRoutes";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { generateRoutesByPermissions } from "./utils";

import { AdminRoutes } from "./AdminRoutes";
import { Toaster } from "@miview/toast";
import { Toast } from "@miview/components";
import {
  accountingRoutes,
  routes,
  schedulingRoutes,
  adminSettingsRoutes,
  purchasingRoutes,
} from "./routes";
import { DefaultLayout } from "./containers";

const GlobalToast = () => {
  return (
    <Toaster>
      <Toast />
    </Toaster>
  );
};

export const muiCache = createCache({
  key: "mui",
  prepend: true,
});

const tssCache = createCache({
  key: "tss",
});

const login = [
  { path: "/login", name: "Login Screen", element: <LoginScreen /> },
  {
    path: "/resetpassword",
    name: "Reset Password",
    element: <ResetScreen />,
  },
];

const schedulingCombined = [
  {
    path: "scheduling",
    name: "MiScheduling",
    element: <SchedulingRoutes />,
    errorElement: <ErrorView />,
    children: generateRoutesByPermissions(schedulingRoutes),
  },
];

const purchasingCombined = [
  {
    path: "purchasing",
    name: "MiPurchasing",
    element: <PurchasingRoutes />,
    errorElement: <ErrorView />,
    children: generateRoutesByPermissions(purchasingRoutes),
  },
];

const adminCombined = [
  {
    path: "settings",
    name: "MiPurchasing",
    element: <AdminRoutes />,
    errorElement: <ErrorView />,
    children: generateRoutesByPermissions(adminSettingsRoutes),
  },
];

const accountingCombined = [
  {
    path: "accounting",
    name: "MiAccounting",
    element: <AccountingRoutes />,
    errorElement: <ErrorView />,
    children: generateRoutesByPermissions(accountingRoutes),
  },
];

const combinedRoutes = [
  ...login,
  {
    path: "/",
    name: "Home",
    element: <DefaultLayout />,
    errorElement: <ErrorView />,
    children: generateRoutesByPermissions(routes),
  },
  ...schedulingCombined,
  ...purchasingCombined,
  ...adminCombined,
  ...accountingCombined,
];

export const history = createBrowserHistory({ basename: "" });
const reactPlugin = new ReactPlugin();

export const createMuiTheme = createTheme({
  ...MiTHEME,
  palette: {
    ...MiTHEME.palette,
    primary: {
      ...MiTHEME.palette.primary,
      main: MiTHEME.colors.teal.primary,
      secondary: MiTHEME.colors.teal.secondary,
      accent: MiTHEME.colors.grey.primary,
      light: MiTHEME.colors.teal.light,
    },
  },
  spacing: (factor) => `${0.25 * factor}`,
});

export const AppProvider = ({ children }) => {
  return <ThemeProvider theme={createMuiTheme}>{children}</ThemeProvider>;
};

const App = (props) => {
  global.baseUrl = props.apiUrl;

  useEffect(() => {
    unregister();
  }, []);

  const router = createBrowserRouter(combinedRoutes);

  return (
    <AppInsightsProvider reactPlugin={reactPlugin} history={history}>
      <CacheProvider value={muiCache}>
        <TssCacheProvider value={tssCache}>
          <AppProvider>
            <GlobalToast />
            <RouterProvider router={router} />
          </AppProvider>
        </TssCacheProvider>
      </CacheProvider>
    </AppInsightsProvider>
  );
};

export default withAITracking(reactPlugin, App, "MIVIEW WEB");
