import React, { memo, Component, useState } from "react";
import PropTypes from "prop-types";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Tooltip from "@mui/material/Tooltip";
import { isStackUnresolved, boundaryMismatch } from "../../utils";
import { THEME } from "@miview/theme";
import { useAreaItemFormStyles } from "./MiAreaItemFormStyles";
import { PaddedDiv } from "./StyledComponents/PaddedDiv";
import { PlanItemImageLabel } from "./StyledComponents/PlanItemImageLabel";
import { PlanItemImageFocus } from "./StyledComponents/PlanItemImageFocus";
import { getImageUrl, getImageBase64FromFile } from "@miview/utils";
import { MiDetailFields, MiButton } from "@miview/components";
import { useCdn, useEdit } from "@miview/hooks";

const TOLERANCE_OPTIONS = [
  {
    value: `1/4"`,
    text: `1/4"`,
  },
  {
    value: `1/2"`,
    text: `1/2"`,
  },
  {
    value: `3/4"`,
    text: `3/4"`,
  },
  {
    value: `1"`,
    text: `1"`,
  },
  {
    value: `1 1/2"`,
    text: `1 1/2"`,
  },
  {
    value: `2"`,
    text: `2"`,
  },
  {
    value: `3"`,
    text: `3"`,
  },
  {
    value: `6"`,
    text: `6"`,
  },
];

const WARNING_TEXT =
  "One or more measurement types does not fit existing boundaries";

const boxColor = (itemDetails) => {
  if (!itemDetails?.itemId) {
    return THEME.STEPS_BAR[0];
  }

  if (itemDetails?.isDeleted) {
    return THEME.RED_PRIMARY;
  }

  if (
    !itemDetails?.measurementBackTypeId ||
    !itemDetails?.measurementBackValue ||
    !itemDetails?.measurementSideTypeId ||
    !itemDetails?.measurementSideValue ||
    !itemDetails.cadImageX ||
    !itemDetails.cadImageY
  ) {
    return THEME.ORANGE_PRIMARY;
  }

  return THEME.GREEN_PRIMARY;
};

export class ImageContainer extends Component {
  componentDidMount() {
    this.nv.addEventListener("paste", this.onPasteImageHandler, true);
  }

  componentWillUnmount() {
    this.nv.removeEventListener("paste", this.onPasteImageHandler, true);
  }

  onPasteImageHandler = (pasteEvent) => {
    const items = pasteEvent.clipboardData.items;

    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") == -1) {
        continue;
      }
      const blob = items[i].getAsFile();

      getImageBase64FromFile(blob, (e) =>
        this.props.handleUpdateImage(e, this.props.position)
      );
    }
  };

  render() {
    const { itemDetails, handleUpdateImage, imageSrc, position, classes, cdn } =
      this.props;
    const handleCreateDrawing = (e) => {
      getImageBase64FromFile(e.target.files[0], (f) =>
        handleUpdateImage(f, position)
      );
    };

    const renderImage = () => {
      if (imageSrc) {
        return <img id="image-container" src={getImageUrl(imageSrc, cdn)} />;
      } else {
        return (
          <div
            id="image-container"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 18,
            }}
          >
            <CameraAltIcon className={classes.areaImageIcon} />
          </div>
        );
      }
    };

    return (
      <PlanItemImageFocus
        tabIndex="0"
        forwardedRef={(elem) => (this.nv = elem)}
      >
        <div>
          <PlanItemImageLabel>{position}</PlanItemImageLabel>
          <input
            className={classes.createDrawingInput}
            type="file"
            id={`drawing-${itemDetails?.itemId || 0}-${position}`}
            name={`drawing-${itemDetails?.itemId || 0}-${position}`}
            accept="image/png, image/jpeg"
            onChange={handleCreateDrawing}
          ></input>
          <label
            htmlFor={`drawing-${itemDetails?.itemId || 0}-${position}`}
            className={classes.createDrawingLabel}
          >
            <Tooltip
              title="Click to upload or paste an image from clipboard"
              aria-label="Add Image"
            >
              <div className={classes.areaItemFormImage}>{renderImage()}</div>
            </Tooltip>
          </label>
        </div>
      </PlanItemImageFocus>
    );
  }
}

ImageContainer.propTypes = {
  itemDetails: PropTypes.object,
  handleUpdateImage: PropTypes.func,
  imageSrc: PropTypes.string,
  position: PropTypes.string,
};

const MiStackForm = ({
  itemDetails,
  handleUpdate,
  walkTypes,
  measurementTypesBack,
  measurementTypesSide,
  stackSizes,
  cadBoundaries,
  stackEdit,
  handleCancel,
  propertyPlanAreaId,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const cdn = useCdn();
  const edit = useEdit(itemDetails);

  const { classes } = useAreaItemFormStyles();

  const handleRemoveImage = (position) => {
    edit.update({
      [`${position}Image`]: null,
    });
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      const stackUpdate = {
        ...stackEdit.edits,
        ...edit.edits,
        propertyPlanAreaId,
      };
      await handleUpdate(stackUpdate);
    } finally {
      setIsSaving(false);
    }
  };

  const handleUpdateImage = (imageData, position) => {
    edit.update({
      [`${position}Image`]: imageData,
    });
  };

  const renderRemoveImageLink = (position) => {
    const imageSrc =
      edit.getValue(`${position}Image`) || itemDetails[`${position}ImageName`];

    if (imageSrc) {
      return (
        <div
          style={{
            color: THEME.RED_PRIMARY,
          }}
          className={classes.areaItemFormRemoveImage}
          onClick={() => handleRemoveImage(position)}
        >
          Remove
        </div>
      );
    }

    return null;
  };

  const isFormDisabled = () => {
    return (
      isSaving ||
      isStackUnresolved({ ...itemDetails, ...edit.edits }, cadBoundaries)
    );
  };

  const detailFields = [
    {
      label: "Name",
      value: edit.getValue("itemName"),
      setValue: (e) => edit.update({ itemName: e }),
      width: "100%",
    },
    {
      label: "Description",
      value: edit.getValue("description"),
      setValue: (e) => edit.update({ description: e }),
      width: "100%",
    },
    {
      label: "Work",
      fieldType: "select",
      value: edit.getValue("walkTypeId"),
      options: walkTypes.map((t) => {
        return { value: t.walkTypeId, text: t.walkTypeName };
      }),
      required: true,
      setValue: (e) => edit.update({ walkTypeId: e }),
      width: "100%",
    },
    {
      label: `Measure From Back`,
      options: measurementTypesBack,
      fieldType: "select",
      value: edit.getValue("measurementBackTypeId"),
      required: true,
      setValue: (e) => edit.update({ measurementBackTypeId: e }),
      width: "33%",
      minWidth: 120,
    },
    {
      label: "Measurement Back",
      value: edit.getValue("measurementBackValue"),
      required: true,
      setValue: (e) =>
        edit.update({
          measurementBackValue: e,
        }),
      width: "33%",
      minWidth: 120,
    },
    {
      label: `Tolerance Back`,
      options: TOLERANCE_OPTIONS,
      fieldType: "select",
      value: edit.getValue(`toleranceBack`),
      setValue: (e) =>
        edit.update({
          toleranceBack: e,
        }),
      width: "33%",
      minWidth: 120,
    },
    {
      label: `Measure From Side`,
      options: measurementTypesSide,
      fieldType: "select",
      value: edit.getValue("measurementSideTypeId"),
      required: true,
      setValue: (e) => edit.update({ measurementSideTypeId: e }),
      width: "33%",
      minWidth: 120,
    },
    {
      label: "Measurement Side",
      value: edit.getValue("measurementSideValue"),
      required: true,
      setValue: (e) =>
        edit.update({
          measurementSideValue: e,
        }),
      width: "33%",
      minWidth: 120,
    },
    {
      label: `Tolerance Side`,
      options: TOLERANCE_OPTIONS,
      fieldType: "select",
      value: edit.getValue(`toleranceSide`),
      setValue: (e) =>
        edit.update({
          toleranceSide: e,
        }),
      width: "33%",
      minWidth: 120,
    },
    {
      label: `Stack Size`,
      options: stackSizes,
      fieldType: "select",
      value: edit.getValue(`stackSizeId`),
      setValue: (e) => edit.update({ stackSizeId: e }),
      width: "50%",
      minWidth: 120,
      required: true,
    },
    {
      label: `Can Be Not Applicable`,
      fieldType: "checkbox",
      value: edit.getValue("canBeNotApplicable"),
      setValue: (e) => edit.update({ canBeNotApplicable: e }),
      width: "50%",
    },
  ];

  return (
    <PaddedDiv>
      <div
        className={classes.areaItemFormBox}
        style={{
          borderLeft: `12px solid ${boxColor(itemDetails)}`,
          padding: "8px 8px 8px 12px",
        }}
      >
        <div style={{ display: "flex" }}>
          <div className={classes.areaItemFormDetail}>
            <MiDetailFields detailFields={detailFields} />
            <MiButton
              style={{ borderWidth: 0 }}
              title="Delete Stack Icon"
              onClick={() => edit.update({ cadImageX: null, cadImageY: null })}
            />
          </div>
          <div>
            <ImageContainer
              position="top"
              itemDetails={itemDetails}
              handleUpdateImage={(e) => handleUpdateImage(e, "top")}
              imageSrc={edit.getValue(`topImage`) || itemDetails.topImageName}
              classes={classes}
              cdn={cdn}
            />
            {renderRemoveImageLink("top")}
          </div>
          <div>
            <ImageContainer
              position="front"
              itemDetails={itemDetails}
              handleUpdateImage={(e) => handleUpdateImage(e, "front")}
              imageSrc={
                edit.getValue(`frontImage`) || itemDetails.frontImageName
              }
              classes={classes}
              cdn={cdn}
            />
            {renderRemoveImageLink("front")}
          </div>
        </div>

        {boundaryMismatch(cadBoundaries, {
          measurementBackTypeId: edit.getValue("measurementBackTypeId"),
          measurementSideTypeId: edit.getValue("measurementSideTypeId"),
        }) && (
          <div className={classes.warningLabel} style={{ color: "red" }}>
            {WARNING_TEXT}
          </div>
        )}
        <div className={classes.areaItemFormFormButtons}>
          <MiButton onClick={handleCancel} color="gray" title="Cancel" />
          <div style={{ flex: 1 }} />
          <MiButton
            onClick={handleSave}
            color="white"
            style={{
              backgroundColor: THEME.BLUE_PRIMARY,
              width: 100,
              borderColor: THEME.BLUE_PRIMARY,
            }}
            title="Save"
            disabled={isFormDisabled()}
          />
        </div>
      </div>
    </PaddedDiv>
  );
};

MiStackForm.propTypes = {
  itemDetails: PropTypes.object,
  editId: PropTypes.number,
  setEditId: PropTypes.func,
  updateForm: PropTypes.func,
  handleUpdate: PropTypes.func,
  setMaterialsModalOpen: PropTypes.func,
  disableMaterials: PropTypes.bool,
  walkTypes: PropTypes.array,
  resetForm: PropTypes.func,
  editItemForm: PropTypes.func,
};

export default memo(MiStackForm);
