import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { MiIcon } from "../MiIcon/MiIcon";
import { mdiCheckboxBlankOutline, mdiCheckboxMarked } from "@mdi/js";
import PropTypes from "prop-types";
import { useTheme } from "@mui/styles";

const MiCheckbox = ({
  checked,
  onChange,
  label,
  labelPlacement,
  disabled,
  clickButton,
  color,
  iconSize,
  fontSize,
  ...rest
}) => {
  const theme = useTheme();
  const icon = checked ? mdiCheckboxMarked : mdiCheckboxBlankOutline;
  let colorIcon = theme.palette.primary.grey;
  if (checked) {
    colorIcon = color || theme.palette.primary.main;
  }
  const renderIcon = () => {
    return <MiIcon path={icon} size={iconSize || 0.8} color={colorIcon} />;
  };

  return (
    <FormControlLabel
      onClick={() => {
        if (disabled && clickButton) {
          clickButton();
        }
      }}
      sx={{
        "& .MuiFormControlLabel-label": {
          fontSize: fontSize || theme.fontSize.small,
        },
      }}
      control={
        <Checkbox
          data-testid="MiCheckbox"
          checked={checked}
          onChange={onChange}
          icon={renderIcon()}
          checkedIcon={renderIcon()}
          disabled={disabled}
          inputProps={{ readOnly: true }}
          {...rest}
        />
      }
      label={label}
      labelPlacement={labelPlacement || "end"}
    ></FormControlLabel>
  );
};

MiCheckbox.defaultProps = {
  checked: false,
};

MiCheckbox.propTypes = {
  /**
   * checked value (true | false)
   */
  checked: PropTypes.bool,
  /**
   * handle onChange.
   */
  onChange: PropTypes.func,
  /**
   * Label for checkbox
   */
  label: PropTypes.string,
  /**
   * 'start'||'end' ||'bottom' ||'start'
   */
  labelPlacement: PropTypes.string,
  /**
   * (true|false)
   */
  disabled: PropTypes.bool,
  /**
   * For toggling between edit and non-edit mode
   */
  clickButton: PropTypes.func,
  /**Font size of the Label*/
  labelFontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export { MiCheckbox };
