import { propertyItemService, planService } from "@miview/api";

export const updateWalkItem =
  ({ propertyId, planId, refreshData }) =>
  async (original, edits) => {
    try {
      const requestPayload = { ...edits };
      if (requestPayload.quantity === 0) {
        requestPayload.quantity = undefined;
      }

      if (original.itemId) {
        await propertyItemService.update(original.itemId, requestPayload);
      } else {
        await planService.createPropertyPlanItem(
          planId,
          propertyId,
          requestPayload
        );
      }

      refreshData();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
