import React from "react";
import PropTypes from "prop-types";
import { MiCheckbox } from "@miview/components";

export const AvailableOptionsList = ({
  stageId,
  handleUpdatePropertyOptions,
  propertyOptions,
  propertyOptionsLoading,
}) => {
  const renderList = () => {
    const availableOptions = propertyOptions.availableOptions.filter((o) =>
      o.stageIds.includes(stageId)
    );

    if (availableOptions.length < 1) {
      return <div>No available options found</div>;
    }

    return availableOptions.map((option) => {
      const isChecked = option.isSelected;

      return (
        <div key={option.optionId} style={styles.listItem}>
          <MiCheckbox
            color="orange"
            label={option.optionName}
            checked={isChecked}
            disabled={propertyOptionsLoading}
            onChange={() => handleUpdatePropertyOptions(option, isChecked)}
          />
        </div>
      );
    });
  };

  return (
    <div style={styles.listContainer}>
      <div style={styles.listTitle}>Available Options</div>
      <div style={styles.list}>{renderList()}</div>
    </div>
  );
};

AvailableOptionsList.propTypes = {
  walkTypeId: PropTypes.number,
  handleUpdatePropertyOptions: PropTypes.func,
  propertyOptions: PropTypes.object,
  propertyOptionsLoading: PropTypes.bool,
  stageId: PropTypes.number,
};

const styles = {
  listTitle: {
    color: "#32C5FF",
    padding: "24px 8px 6px",
  },
  list: {
    padding: "10px 8px",
    borderTop: "1px solid #dddbda",
    borderBottom: "1px solid #dddbda",
    display: "flex",
    flexWrap: "wrap",
  },
  listItem: {
    width: 300,
  },
  optionsUpdating: {
    color: "black",
    marginLeft: 10,
    fontSize: 12,
  },
};
