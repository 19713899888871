import React, { useState, useEffect } from "react";
import moment from "moment";
import { createToast } from "@miview/toast";
import { walkService } from "@miview/api";
import { useTheme } from "@mui/material";
import {
  WALK,
  COMPLETE,
  UNASSIGNED,
  INCOMPLETE,
  HTTP_STATUSES,
  TOAST_TYPES,
  WALK_RECORD_TYPES,
  PERMISSIONS,
} from "@miview/constants";
import { makeStyles } from "tss-react/mui";
import { useComponentState, usePermissions } from "@miview/hooks";
import {
  ImageViewer,
  MiDetailFields,
  MiSnackbar,
  MiButton,
} from "@miview/components";
import { CompleteWorkModal } from "./CompleteWorkModal";
import { mdiCurrencyUsd } from "@mdi/js";

export const WorkDetailsTab = (props) => {
  const {
    walkId,
    walkDetails,
    refreshWalk,
    stageTypeName,
    walkStatuses,
    isRedTagMode,
    edit,
    editMode,
    setEditMode,
  } = props;

  const [openImageUrls, setOpenImageUrls] = useState([]);
  const [stageSummaries, setStageSummaries] = useState([]);
  const [stageSummaryIds, setStageSummaryIds] = useState([]);
  const [isCompleteWorkModalOpen, setIsCompleteWorkModalOpen] = useState(false);

  const { classes } = useStyles();
  const theme = useTheme();
  const stateManager = useComponentState();
  const permissions = usePermissions();

  const isWalk = walkDetails?.walkRecordType === WALK;
  const isJob = walkDetails?.recordTypeId === WALK_RECORD_TYPES.JOB;
  const isInspection =
    walkDetails?.recordTypeId === WALK_RECORD_TYPES.INSPECTION;
  const isPunch = walkDetails?.recordTypeId === WALK_RECORD_TYPES.PUNCH;
  const isFirstAttempt = Boolean(walkDetails?.firstWalk);

  useEffect(() => {
    const incompleteReasonsId = walkDetails.incompleteReasons?.map(
      ({ stageSummaryId }) => stageSummaryId
    );
    const stageSummaryDropdownOptions = walkDetails.incompleteReasons?.map(
      ({ stageSummaryId, stageSummaryDescription }) => {
        return {
          label: stageSummaryDescription,
          value: stageSummaryId,
        };
      }
    );

    if (incompleteReasonsId?.length > 0) {
      setStageSummaries(stageSummaryDropdownOptions);
      setStageSummaryIds(incompleteReasonsId);
    }
  }, [walkDetails]);

  const getAmount = () => {
    if (!permissions.hasPermission(PERMISSIONS.CAN_VIEW_WORK_PAY_AMOUNT)) {
      return "****";
    }
    if (edit.getValue("amount") > 0) {
      return edit.getValue("amount");
    }
    if (
      edit.getValue("amount") === 0 ||
      edit.getValue("amount") === undefined
    ) {
      return 0;
    }
    if (editMode) {
      return edit.getValue("amount");
    }
    if (edit.getValue("amount") < 0) {
      return `-$${Math.abs(edit.getValue("amount")).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    }
    return `$${edit.getValue("amount")?.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const setAmount = (value) => {
    if (!value) {
      return edit.update({ amount: null });
    }
    const regex = /^-?\d{0,6}(\.\d{0,2})?$/;
    if (regex.test(value)) {
      edit.update({ amount: value });
    }
  };

  const handlePayAmount = () => {
    if (
      permissions.hasPermission(PERMISSIONS.CAN_VIEW_WORK_PAY_AMOUNT) &&
      permissions.hasPermission(PERMISSIONS.CAN_MANAGE_WORK_PAY_AMOUNT) &&
      walkDetails.status !== COMPLETE
    ) {
      setEditMode(!editMode);
    }
  };

  const cancelEdit = () => {
    edit.reset();
    setEditMode(false);
  };

  const checkSaveChanges = () => {
    const isComplete =
      edit.getValue("status") === COMPLETE ||
      edit.getValue("status") === INCOMPLETE;

    if (!isInspection && isComplete && !edit.getValue("walkEndDate")) {
      createToast(
        'Submitted On date must be set when status is set to "Complete" or "Incomplete"',
        TOAST_TYPES.ERROR
      );
    } else if (
      !isInspection &&
      moment(edit.getValue("walkEndDate")) <
        moment(edit.getValue("walkBeginDate"))
    ) {
      createToast(
        '"Submitted On" date must be after "Started On" date',
        TOAST_TYPES.ERROR
      );
    } else if (isComplete && edit.edits.isReadyForInspection) {
      setIsCompleteWorkModalOpen(true);
    } else {
      saveChanges();
    }
  };

  const saveChanges = () => {
    setIsCompleteWorkModalOpen(false);
    stateManager.run(async () => {
      const response = await walkService.update(walkId, edit.edits);
      if (response.status === HTTP_STATUSES.OK) {
        refreshWalk();
        setEditMode(false);
      }
    });
  };

  const closeCompleteWorkModal = () => setIsCompleteWorkModalOpen(false);

  const detailFields = [
    {
      label: "Status",
      value: edit.getValue("status"),
      readOnly: !editMode,
      fieldType: "select",
      setValue: (e) => edit.update({ status: e }),
      options: walkStatuses,
      clickButton: () => setEditMode(!editMode),
      width: "50%",
      minWidth: 0,
      minInputWidth: 0,
    },
    {
      label: "Scheduled Date",
      value: edit.getValue("scheduledStartDate"),
      setValue: (e) => edit.update({ scheduledStartDate: e }),
      readOnly: !(edit.getValue("status") === UNASSIGNED && editMode),
      fieldType: "date",
      inputFormat: "MM/DD/YYYY",
      dateFormat: "YYYY-MM-DDThh:mm:ss",
      width: "50%",
      minWidth: 0,
      minInputWidth: 0,
      hide: isInspection,
    },
    {
      label: "Ready for Inspection Date",
      value: edit.getValue("readyForInspectionDate"),
      readOnly: true,
      fieldType: "date",
      inputFormat: "MM/DD/YYYY",
      dateFormat: "YYYY-MM-DDThh:mm:ss",
      width: "50%",
      minWidth: 0,
      minInputWidth: 0,
      hide: !isInspection,
    },
    {
      label: "Inspection Scheduled Date",
      value: edit.getValue("scheduledStartDate"),
      setValue: (e) => edit.update({ scheduledStartDate: e }),
      readOnly: !editMode,
      fieldType: "date",
      inputFormat: "MM/DD/YYYY",
      dateFormat: "YYYY-MM-DDThh:mm:ss",
      clickButton: () => setEditMode(!editMode),
      hide: !isInspection,
      width: "50%",
      minWidth: 0,
      minInputWidth: 0,
    },
    {
      label: "Reported on Date",
      value: edit.getValue("reviewedOnDate"),
      readOnly: !editMode,
      setValue: (e) => edit.update({ reviewedOnDate: e }),
      fieldType: "date",
      dateType: "date",
      inputFormat: "MM/DD/YYYY",
      dateFormat: "YYYY-MM-DDThh:mm:ss",
      clickButton: () => setEditMode(!editMode),
      hide: !isInspection,
      width: "50%",
      minWidth: 0,
      minInputWidth: 0,
    },
    {
      label: "Started On",
      value: edit.getValue("walkBeginDate"),
      readOnly: !editMode,
      setValue: (e) => edit.update({ walkBeginDate: e }),
      fieldType: "date",
      dateType: "date",
      inputFormat: "MM/DD/YYYY",
      dateFormat: "YYYY-MM-DDThh:mm:ss",
      clickButton: () => setEditMode(!editMode),
      hide: isInspection,
      width: "50%",
      minWidth: 0,
      minInputWidth: 0,
    },
    {
      label: "Inspected on Date",
      value: edit.getValue("walkEndDate"),
      readOnly: true,
      setValue: (e) => edit.update({ walkEndDate: e }),
      fieldType: "date",
      dateType: "date",
      inputFormat: "MM/DD/YYYY",
      dateFormat: "YYYY-MM-DDThh:mm:ss",
      hide: !isInspection,
      width: "50%",
      minWidth: 0,
      minInputWidth: 0,
    },
    {
      label: "Submitted On",
      value: edit.getValue("walkEndDate"),
      readOnly: !editMode,
      setValue: (e) => edit.update({ walkEndDate: e }),
      fieldType: "date",
      dateType: "date",
      inputFormat: "MM/DD/YYYY",
      dateFormat: "YYYY-MM-DDThh:mm:ss",
      clickButton: () => setEditMode(!editMode),
      hide: isInspection,
      width: "50%",
      minWidth: 0,
      minInputWidth: 0,
    },
    {
      label: "Pay Amount",
      value: getAmount(),
      fieldType: "number",
      readOnly:
        !editMode ||
        !permissions.hasPermission(PERMISSIONS.CAN_MANAGE_WORK_PAY_AMOUNT) ||
        walkDetails.status === COMPLETE,
      clickButton: handlePayAmount,
      setValue: (val) => setAmount(val),
      hide: isWalk || isPunch || !isFirstAttempt || isInspection,
      width: "50%",
      minWidth: 0,
      minInputWidth: 0,
    },
    {
      label: "Could Not Start Reason",
      value: edit.getValue("walkFailureTypeName"),
      readOnly: true,
      hide: isJob || isInspection,
      width: "50%",
      minWidth: 0,
      minInputWidth: 0,
    },
    {
      label: "Could Not Complete Reason",
      fieldType: "multiSelect",
      value: stageSummaryIds,
      readOnly: true,
      options: stageSummaries.length > 0 ? stageSummaries : [{}],
      hide: isInspection,
      setValue: () => null,
      width: "50%",
      minWidth: 0,
      minInputWidth: 0,
    },
    {
      label: "Assigned To",
      value: edit.getValue("assignedUserName"),
      readOnly: true,
      fieldType: "text",
      hide: isInspection,
      width: "50%",
      minWidth: 0,
      minInputWidth: 0,
    },
    {
      label: "Ready For Inspection",
      clickButton: () => setEditMode(true),
      readOnly: !editMode,
      value: edit.getValue("isReadyForInspection"),
      setValue: (e) => edit.update({ isReadyForInspection: e }),
      fieldType: "checkbox",
      hide: !isWalk,
      width: "50%",
      minWidth: 0,
      minInputWidth: 0,
    },
    {
      label: isInspection ? "Inspection" : "Work",
      field: "attachment-file",
      fieldType: "image",
      value: edit.getValue("image") || walkDetails?.walkImageName,
      setValue: (e) => {
        edit.update({
          image: e.base64,
          fileName: e.fileName,
          fileType: e.fileType,
        });
      },
      readOnly: !editMode,
      clickButton: () => setEditMode((prev) => !prev),
      width: "50%",
      clickImage: () => {
        setOpenImageUrls([walkDetails?.walkImageName]);
      },
      showInput: true,
      minWidth: 0,
      minInputWidth: 0,
    },
    {
      label: "Notes",
      value: edit.getValue("summaryNotes"),
      setValue: (e) => edit.update({ summaryNotes: e }),
      readOnly: !editMode,
      clickButton: () => setEditMode(!editMode),
      fieldType: "text",
      width: "100%",
      minWidth: 0,
      minInputWidth: 0,
      rows: 4,
      multiline: true,
    },
    {
      label: "Signature",
      clickButton: () => setEditMode(!editMode),
      fieldType: "image",
      value:
        edit.getValue("signatureImage") || edit.getValue("signatureImageName"),
      readOnly: !editMode,
      setValue: (e) => {
        edit.update({
          signatureImage: e.base64,
          fileName: e.fileName,
          fileType: e.fileType,
        });
      },
      hide: isInspection,
      minWidth: 0,
      minInputWidth: 0,
    },
  ];

  const redTagFields = [
    {
      label: "Inspection Scheduled Date",
      value: edit.getValue("scheduledStartDate"),
      setValue: (e) => edit.update({ scheduledStartDate: e }),
      fieldType: "date",
      readOnly: true,
      inputFormat: "MM/DD/YYYY",
      dateFormat: "YYYY-MM-DDThh:mm:ss",
      width: "50%",
      minWidth: 0,
      minInputWidth: 0,
    },
    {
      label: "Reschedule Date",
      value: edit.getValue("nextAttemptScheduledStartDate"),
      setValue: (e) => edit.update({ nextAttemptScheduledStartDate: e }),
      fieldType: "date",
      dateType: "date",
      inputFormat: "MM/DD/YYYY",
      dateFormat: "YYYY-MM-DDThh:mm:ss",
      width: "50%",
      minWidth: 0,
      minInputWidth: 0,
    },
    {
      label: "Date of Inspection",
      value:
        edit.getValue("walkEndDate") || edit.getValue("scheduledStartDate"),
      setValue: (e) => edit.update({ walkEndDate: e }),
      fieldType: "date",
      dateType: "date",
      inputFormat: "MM/DD/YYYY",
      dateFormat: "YYYY-MM-DDThh:mm:ss",
      required: true,
      width: "50%",
      minWidth: 0,
      minInputWidth: 0,
    },
    {
      label: "Reported on Date",
      value: edit.getValue("reviewedOnDate"),
      setValue: (e) => edit.update({ reviewedOnDate: e }),
      fieldType: "date",
      dateType: "date",
      inputFormat: "MM/DD/YYYY",
      dateFormat: "YYYY-MM-DDThh:mm:ss",
      required: true,
      width: "50%",
      minWidth: 0,
      minInputWidth: 0,
    },
    {
      label: "Notes",
      value: edit.getValue("stageSummary"),
      setValue: (e) => edit.update({ stageSummary: e }),
      multiline: true,
      rows: 4,
      fieldType: "text",
      width: "100%",
      minWidth: 0,
    },
    {
      value: edit.getValue("image"),
      setValue: (e) => edit.update({ image: e }),
      fieldType: "image",
      width: "20%",
      minWidth: 0,
      clipBoardItem: true,
    },
  ];

  const renderRecalc = () => {
    if (isJob && isFirstAttempt) {
      return (
        <MiButton
          title={"Recalc Pay"}
          icon={mdiCurrencyUsd}
          color={theme.palette.primary.green}
          onClick={recalculateWorkPay}
          width={150}
          className={classes.recalcButton}
        />
      );
    }
  };

  const recalculateWorkPay = () => {
    stateManager.run(async () => {
      const response = await walkService.recalculateWorkPay(walkId);
      const pay = response.data;
      if (edit.getValue("amount") !== pay) {
        edit.update({ amount: pay });
      }
      setEditMode(true);
    });
  };

  return (
    <>
      <CompleteWorkModal
        open={isCompleteWorkModalOpen}
        onClose={closeCompleteWorkModal}
        onConfirm={saveChanges}
        stageTypeName={stageTypeName}
      />
      <MiSnackbar
        visible={editMode}
        handleCancelClick={cancelEdit}
        handleSaveClick={checkSaveChanges}
      />
      <ImageViewer
        images={openImageUrls}
        selectedImage={openImageUrls[0]}
        onUnfullscreen={() => setOpenImageUrls([])}
        fullScreenStatus={openImageUrls.length}
      />
      <div className={classes.divider} />
      <MiDetailFields
        detailFields={isRedTagMode ? redTagFields : detailFields}
        headerProps={{
          icon: "list",
          title: "Detail Fields",
          button: renderRecalc(),
        }}
      />
    </>
  );
};

const useStyles = makeStyles()(() => ({
  recalcButton: {
    margin: "5px 5px",
  },
  divider: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "1rem",
  },
}));
