import React from "react";
import {
  MiInputSelectBox,
  MiCheckbox,
  MiButton,
  MiSectionHeader,
  MiBox,
} from "@miview/components";
import { mdiDelete } from "@mdi/js";
import { THEME } from "@miview/theme";
import { useTheme, Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  rowContainer: {
    display: "flex",
    ...THEME.ROW,
    justifyContent: "space-between",
    borderBottom: "1px solid #DDD",
    marginBottom: 5,
  },
  insideRow: {
    ...THEME.ROW,
    display: "flex",
    flex: 1,
  },
  checkBox: { minWidth: 140 },
  walkTypeName: { marginRight: 20, minWidth: 160, fontSize: 12 },
}));

export const ViewableWalkTypes = ({ edit, walkTypes = [] }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const viewWalkTypes = edit.getValue("viewWalkTypes") || [];
  const handleChecked = (e, id) => {
    edit.update({
      viewWalkTypes: viewWalkTypes.map((wt) => {
        if (wt.walkTypeId === id) {
          return { ...wt, isAssignable: e.target.checked };
        }
        return wt;
      }),
    });
  };

  const handleRemove = (id) => {
    edit.update({
      viewWalkTypes: viewWalkTypes.filter((wt) => wt.walkTypeId !== id),
    });
  };

  const handleAdd = (e) => {
    if (!e.target?.value) {
      return;
    }
    const val = e.target.value;
    const walkTypeToAdd = walkTypes.find((wt) => wt.value === val) || {};
    edit.update({
      viewWalkTypes: [
        ...viewWalkTypes,
        {
          ...walkTypeToAdd,
          isAssignable: false,
          walkTypeName: walkTypeToAdd.label,
          walkTypeId: walkTypeToAdd.value,
        },
      ],
    });
  };

  return (
    <>
      <MiBox boxShadow={"0px 0px 2px 0px rgba(0,0,0,.2)"}>
        <MiSectionHeader title={"Add Viewable Walk Type Settings"} />
        <MiInputSelectBox
          labelText="Select to Add Walk Type"
          handleChange={handleAdd}
          menuItems={walkTypes.filter(
            (wt) => !viewWalkTypes.some((x) => x.walkTypeId === wt.value)
          )}
        />
        {viewWalkTypes?.map((walkType, i) => {
          return (
            <Grid
              key={`${walkType.walkTypeId}-${i}`}
              className={classes.rowContainer}
            >
              <Grid className={classes.insideRow}>
                <Grid className={classes.walkTypeName}>
                  {walkType.walkTypeName}
                </Grid>
                <Grid className={classes.checkBox}>
                  <MiCheckbox
                    sx={{ fontSize: 5 }}
                    label={"Can Assign"}
                    checked={walkType.isAssignable}
                    onChange={(e) => handleChecked(e, walkType.walkTypeId)}
                  />
                </Grid>
              </Grid>
              <MiButton
                icon={mdiDelete}
                iconSize={0.8}
                style={{ border: "none" }}
                color={theme.palette.primary.red}
                onClick={() =>
                  handleRemove(walkType.walkTypeId || walkType.value)
                }
              />
            </Grid>
          );
        })}
      </MiBox>
    </>
  );
};
